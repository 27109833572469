.cont-datosmedi {
    background-color: #028d7b;
    height: 300px;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
}
.cont-titulomedi{
    text-align: center;
    background-color: #028d7b ;
}
.titulomedi{
    font-size: 25px;
    color: #fff;
}
.datoslabel {
    color: #fff;
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
    
}
.cont-tablamedi{
    color: rgb(7, 5, 5);
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
}
.tablamedi1{
    background-color: #18ccb4;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
}
.cont-listmedi {
    color: rgb(7, 5, 5);
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
  
  .listmedi {
    height: 280px;
    width: 100%;
    width: auto;
    overflow: auto;
  }
  
  .tablame {
    color: #fff;
    background-color: #7ac5cd;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 18px;
    border-collapse: collapse;
    /* margin: 0 0 1em 0; */
    caption-side: top;
    border-collapse: collapse;  
  }
  .accionesmedi{
    margin: 0px 0px 15px 15px;
    flex: 0%;
    text-align: center;

}
.botonmediOff {
    color: rgba(182,184,214,1);
    background: transparent;
    outline: none;
    border: solid 1px rgb(18, 149, 167);;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
}    

.botonmedi {
    color: #028d7b;
    background-color: #fff;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
    margin-top: 40px;
}
.botonmedi:hover {
    color:  #fff;
    background-color: rgb(35, 80, 69)
}
.titulo-medicamento{
    color: #fff;
    font: bold;
    font-size: 22px;
    margin-left: 35px;       
}
.titulo-eliminar{
    color: #fff;
    font: bold;
    font-size: 20px;
    margin-left: 250px;       
}
.cerrarme1{
    background-color: #fc1515;
    background-image: url('../../imagenes/borrar.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block ;
    cursor: pointer;
    margin-top: 2px;
  }


