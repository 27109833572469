.containerprin{
    height: 100%;
    width: 100%;
    align-Content: center;
    justify-Content: center;
}
.cont-prin{
    background-color: #028d7b;
    height: 450px;
}
.grabar, .cont-titulogra, .reproducir, .botonesgra, .botonesgeneral{
    text-align: center;
}
.titulo-grabador{
    font-size: 30px;
    color: #ffffff;
    text-align: center;
}
.sound-wave{
    margin-top: 20px;
    width: 450px;    
}
.output{
    width: 400px;
    margin-top: 20px;
}
.botonesgra, .botonesgeneral{
    margin-top: 20px;    
}
.botonesgeneral{
    margin-top: 40px;    
}
.botongrOff {
    color: rgba(182,184,214,1);
    background: transparent;
    outline: none;
    border: solid 1px rgb(18, 149, 167);;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
}    
.botongr{
    color: #028d7b;
    background-color: #fff;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
}
.botongr:hover {
    color:  #fff;
    background-color: rgb(35, 80, 69)
}

.recButtonOn {
    background-color: #48e120;
    background-image: url('../../imagenes/microfono.png');
    background-size: 50px;
    background-repeat: no-repeat; 
    width: 60px;
    height: 60px;
    background-position:center;
    border-radius: 50px;
    border: none;
}
.recButtonOff{
    background-image: url('../../imagenes/microfono.png');
    background-size: 50px;
    background-repeat: no-repeat; 
    width: 60px;
    height: 60px;
    background-position:center;
    border-radius: 50px;
    border: none;
   
}
.stopButtonOn{
    background-color: #48e120;
    background-image: url('../../imagenes/stop.png');
    background-size: 110px;
    background-repeat: no-repeat; 
    width: 60px;
    height: 60px;
    background-position:center;
    border-radius: 50px;
    border: none;
    margin-left: 30px;
}
.stopButtonOff{
    background-image: url('../../imagenes/stop.png');
    background-size: 120px;
    background-repeat: no-repeat; 
    width: 60px;
    height: 60px;
    background-position:center;
    border-radius: 50px;
    border: none;
    margin-left: 30px;
}
.eliButtonOn {
    background-color: #48e120;
    background-image: url('../../imagenes/borrar.png');
    background-size: 50px;
    background-repeat: no-repeat; 
    width: 60px;
    height: 60px;
    background-position:center;
    border-radius: 50px;
    border: none;
    margin-left: 30px;
}
.eliButtonOff{
    background-image: url('../../imagenes/borrar.png');
    background-size: 50px;
    background-repeat: no-repeat; 
    width: 60px;
    height: 60px;
    background-position:center;
    border-radius: 50px;
    border: none;
    margin-left: 30px;  
}
.listevogra {
    border: 15px;
    position:fixed;
    top: 35%;
    left: 20%;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 320px;
    justify-content: center;
    font-size: 16px;
    width: auto;
    overflow: auto;
  
    background-color:  #028d7b;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
  
    z-index:100;
  
   
    }
    .evotabla2gra {
      color: #fff;
      background-color:  #028d7b;
      width: 100%;
      border: 1px solid  #028d7b;
      text-align: left;
      border-collapse: collapse;
      margin: 0 0 1em 0;
      caption-side: top;
    }
    .evocuerpo1{
        cursor: pointer;
    }
    .cont-evogra{
        border: 15px;
        position:fixed;
        top: 25%;
        left: 20%;
        right: 0;
        margin: 0 auto;
        width: 34%;
        height: 385px;
        justify-content: center;
        font-size: 14px;
        background-color:  #028d7b;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
        z-index:80;
        
    }
    .evogra{
        text-align: center;
        background-color: #028d7b;
        height: 100%px;
        width: 100%;
    }
    .titulo-listevo{
        font-size: 25px;
        color: #48e120;
        text-align: center;
    }
    .titulo-listcerrar{
        font-size: 30px;
        color: red;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font: bold;
        cursor: pointer;
        float: right;
    }
    .titulo-listcerrar2{
        font-size: 30px;
        color: red;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font: bold;
        cursor: pointer;
        float: right;
        margin-right:  15px;
    }
    .cont-spinner{
        border: 15px;
        position:fixed;
        top: 35%;
        left: 20%;
        right: 0;
        margin: 0 auto;
        width: 25%;
        height: 385px;
        justify-content: center;
        z-index: 150;

    }
    .spinner{ 
        display: block;
        margin: 0 auto;
        border-color: red;
    }
    .flotador {
        height: auto !important;
    }
    .icono-izquierdan2 {
        margin-right: 10px;
        font-size: 20px;
        color: red;
        position: absolute;
        float: right;
        right: 0;
        top: 30px;
        cursor: pointer;
    }
    .output2{
        margin-top: auto;
        float: right;
        margin-right: 30px;
        background-color: #028d7b;
    }
    