.cont-titulogra{
    /* margin-bottom: 20px; */
}
.containerindica{
    height: 100%;
    width: 100%;
    align-Content: center;
    justify-Content: center;
}
.cont-indica{
    background-color: rgb(172, 199, 140);
    height: 500px;
}
.nombre-medica{
    text-align: left;
}
.nombremedica{
    font-size: 20px;
    color: red;
}
.titulo-indi{
    text-align: center;
}
.tituloindi{
    font-size: 15px;
    color: blue;
}
.nombre-composicion{
    text-align: left;    
}
.nombrecomposicion{
    font-size: 11px;
    color: black;
}
.nombre-presen{
    text-align: left;    
}
.nombrepresen{
    font-size: 11px;
    color: black;
}
.nombre-dosifi{
    text-align: left;    
}
.nombredosifi{
    font-size: 11px;
    color: black;
}
#dosifi{
    height: 60px;
}
.botonesgeneral{
    margin-top: 20px;    
}
.botonesgeneral{
    margin-top: 40px;    
}
.botongrOff {
    color: rgba(182,184,214,1);
    background: transparent;
    outline: none;
    border: solid 1px rgb(18, 149, 167);;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
}    
.botongr{
    color: #028d7b;
    background-color: #fff;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
}
.botongr:hover {
    color:  #fff;
    background-color: rgb(35, 80, 69)
}

    .titulo-listevo{
        font-size: 25px;
        color: #48e120;
        text-align: center;
    }
    .titulo-listcerrar{
        font-size: 30px;
        color: red;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font: bold;
        cursor: pointer;
        float: right;
    }
