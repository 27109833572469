.cont-quimioc {
    background-color: #028d7b; 
    height: 100%;
    width: 100%;
    align-Content: center;
    justify-Content: center;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;

}
.cont-tituloquimioc {
    text-align: center;
}
.tituloquimioc {
    text-align: center;
    font-size: 25px;
    color: #fff;   
}
.titulo-cerrarquimioc {
    font-size: 30px;
    color: red;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font: bold;
    cursor: pointer;
    float: right;
}

#nombredr, #colega, #referido, #presenta,#practicado, #fechad {
width: 100%;
outline: none;
margin: 0;
font-size: 14px;
height: 25px;
} 


.cont-opcion {
    margin-bottom: 15px ;
}
.cont-opcion2 {
    margin-bottom: 15px;
    text-align: center;
    color: #fff;
    font-size: 14px;
}
.spanradio {
    font-size: 13px !important;
    color: seashell !important;
}
.form-radioM{
    height: 20px !important;
}
#admin{
    margin-top: 10px;
    font-size: 16px;
}