
.col-cabecera {
  display: flex;
  box-shadow: 0px 1px 1px 0px rgb(167, 167, 233); 
  transition:0,4s;
  text-align: center;
  background-color: #028d7b;
}
.logo1 {
    width: 45px;
}

.logo1 {
    animation-iteration-count: infinite;
    animation-duration: 20s;
    transform-style: preserve-3d;
    animation-name: slidein;
  }
  
  @keyframes slidein {
    from {
        transform: rotateY(-360deg);

    }
  
    to {
        transform: rotateY(360deg);
    }
  }
  
.usu-cabecera{
    margin-top: 10px;
    font-size: 30px;
    color: #fff;

}
