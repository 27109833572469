.botonF1{
  background-image: url('../../imagenes/chat.png');
  width:60px;
  height:60px;
  background-size: 60px;
  background-repeat: no-repeat;   
  border-radius:100%;
  left:0;
  bottom:0;
  position:absolute;
  margin-left:16px;
  margin-bottom:16px;
  border:none;
  outline:none !important;
  color:#FFF;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  text-decoration: none;
}
.botonF2{
    width:60px;
    height:60px;
    border-radius:100%;
    left:0;
    bottom:0;
    background:#F44336;
    position:absolute;
    margin-left:16px;
    margin-bottom:16px;
    border:none;
    outline:none !important;
    font-size:36px;
    color:#FFF;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    text-decoration: none;
  }

  .botonF1:active{
    transform:scale(1.01);
  }
  .botonF2 :active{
    transform:scale(1.01);
  }

  .cont-chat-formulario {
        text-align: center;
        background-color: #057a71;
        width: 100%;
        height: 450px;
        border: 3px solid  rgba(182,184,214,1);
        position:fixed;
        top: 10%;
        left: 10px;
        margin: 0 auto;
        justify-content: center;
        z-index: 150;
  }
  .titulochat{
      color: #fff;
      font-size: 25px;
  }
  .cont-titulochat{
    background-color: #057463;
    margin-top: 5px !important;
  }
  .capa1 {
    height: 20px;
  }
  
  .capa2 {
    margin-top: 10px;
    height: 350px;
    overflow: auto;
    text-align: left !important;
    color: #fff !important;
    position: -webkit-sticky;
    position:sticky;
    top:250px;
  
    
  }

  .labelmensaje {
    font-size: 14px;
    color: #fff;
    
  }
 
  .cont-texto{
    margin-top: 350px !important;
    text-align: left;
  }
  .remitente{
    color: rgb(9, 247, 247);
    font-size: 20px;
    font: bold;
  }
  .inputtext {
    padding: 0;
  }
  .botochat{
    justify-content: center;
  }
  .botonch{
    color: #028d7b;
    background-color: #fff;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-top: 10px;
}
.botonch:hover {
    color:  #fff;
    background-color: rgb(35, 80, 69)
}
.mensajerecibido{
  /* background-image: url('../../imagenes/chat.png'); */
  width:60px;
  height:60px;
  background-size: 60px;
  border-radius:100%;
  left:0;
  bottom:0;
  font-size: 0;
  outline: none;
  outline:none !important;
  position:absolute;
  margin-left:16px;
  margin-bottom:16px;
  background-color: red;
  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes example {
  from {background-color: red;}
  to {background-color: rgb(248, 248, 10);}
}
 