.cont-historia {
    width: 100%;
    padding: 0px;
  }


.titulo {
    margin: 0;  
    color: #028d7b;
    font-size: 20px;
}
.cont-histo{
    background-color: transparent;
}
.histo, .fecha{
    margin: 0;
    font-size: 12px;
    color: #028d7b;
    width: 150px;
    margin-right: 25px;
    
}

.form-group-header{
    display: flex;
    margin: 0;
    
}

.form-group {
    display: flex;
    margin: 0;
    margin-top: 12px; 
    margin-bottom: 10px; 
}

.historia-contenido{
  width: 100%;  
  background-color: #11a794;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
  margin-bottom: 15px;
}

.group{
    padding: 0;
}

.opcionp {
    color: #fff;
    font-weight: 300;
  }
  
.cont-opcion {
    color: #fff;
    font-size: 14px;
    text-align: left;
    
}
.cont-opcions {
    color: #fff;
    font-weight: 300;
    display: flex;
    margin: 0;
    text-align: center;
    
}

.radioM, .radioF {
    text-align: center;
    color: aquamarine;
    
}

.form-radioM, .form-radioF{
    margin-left: 2px;
    margin: 0px 0;
    display: inline;
   
}



#cedula, #correopa, #nombre, #apellido, #fnacimiento, #edad, #direccionh, #telefonoh, #direcciont, #telefonot, #ocupacion, #referidor, #motivo {
    width: 100%;
    outline: none;
    margin: 0;
    font-size: 14px;
    height: 25px;
} 

#examenf, #impresiond, #plan, #antecedentes{
    height: 250px;
    font-size: 12px;
}
.historia-contenidoant{
    z-index: 10;
}

.form-groupant, .form-groupef{
  border: 15px;
  position:fixed;
  top: 30%;
  left: 20%;
  right: 0;
  margin: 0 auto;
  width: 70%;
  justify-content: center;

  background-color: #11a794;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
  margin-bottom: 15px;

  z-index:100;
}

.form-groupev{
    border: 15px;
    position:fixed;
    top: 20%;
    left: 20%;
    right: 0;
    margin: 0 auto;
    width: 70%;
    justify-content: center;
  
    background-color: #11a794;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
  
    z-index:100;
  }

.mce-notification {
    display: none !important;
}

.groupef{
    color: #fff;
    width: 100%;
    height: 280px;
}

.groupev{
    color: #fff;
    width: 100%;
    height: 420px;
}

.groupant{
    color: #fff;
    width: 100%;
    height: 120px;
}

.cont-listadopc {
    color: rgb(7, 5, 5);
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
  
  .listadopc {
    height: 380px;
    width: 100%;
    font-size: 12px;
    width: auto;
    overflow: auto;
  }
  
  .tabla2pc {
    color: #fff;
    background-color: #7ac5cd;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 15px;
    text-align: center;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
  }
  
  .cuerpo{
      cursor: pointer;
  }

  .cuerpo1{
    cursor: pointer;
}


.opcionev{
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.odd{
    background-color: #11a794;
}

.antecedentes{
    font-size: 14px;
    margin-bottom: 20px;
}

.tituevo{
    color: #fff;
    background-color: #028d7b;
    text-align: center;
    padding: 10px;

}

.mce-branding, .mce-path{
    visibility: hidden;
}

.cont-listadocli {
  border: 15px;
  position: fixed;
  top: 30%;
  left: 20%;
  right: 0;
  margin: 0 auto;
  height: 390px;
  justify-content: center;
  width: auto;
  

  background-color: #fff; 
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
  margin-bottom: 15px;

    z-index: 10;  
    
}
  
  .listadocli {
  border: 15px;
  position:fixed;
  top: 35%;
  left: 20%;
  right: 0;
  margin: 0 auto;
  width: 70%;
  height: 300px;
  justify-content: center;
  font-size: 14px;
  width: auto;
  overflow: auto;

  background-color: #11a794;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
  margin-bottom: 15px;

  z-index:100;

 
  }
  
  .tabla2 {
    color: #fff;
    background-color: #7ac5cd;
    width: 100%;
    border: 1px solid #7ac5cd;
    text-align: left;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
  }

.nombre-paciente, .cedula-paciente{
      text-align: center;
      color: #028d7b; 
      font-size: 20px;
  }
  

.icono-izquierdac {
    margin-right: 18px;
    font-size: 14px;
    color: red;
    position: absolute;
    float: right;
    right: 0;
    top: 30px;
    cursor: pointer;

  }

  .icono-izquierdan {
    margin-right: 19px;
    font-size: 14px;
    color: red;
    position: absolute;
    float: right;
    right: 0;
    top: 30px;
    cursor: pointer;
  }
  

.icono-izquierda:hover{
    cursor: pointer;

}


.acciones{
    margin: 15px 0px 15px 0px;
    flex: 0%;
    text-align: center;

}

.botonOf {
    color: rgba(182,184,214,1);
    background: transparent;
    outline: none;
    border: solid 1px rgb(18, 149, 167);;
    font-size: 16px;
    padding: 5px 5px; 
    border-radius: 10px; 
    margin-right: 20px; 
}    

.boton {
    color: #028d7b;
    background-color: #fff;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
}

.botonspan{
    color: #fff;
    background-color: #028d7b;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 12px;
    padding: 1px 1px;
    border-radius: 5px; 
    margin-right: 2px; 
    width: 25px;

}

.botonspanOf{
    color: #028d7b;
    background-color: #fff;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 12px;
    padding: 1px 1px;
    border-radius: 5px; 
    margin-right: 2px; 
    width: 25px;

}

.botonspanocultar {
    color: rgba(182,184,214,1);
    background: transparent;
    outline: none;
    border: solid 1px aquamarine;
    font-size: 12px;
    padding: 1px 1px;
    border-radius: 5px; 
    margin-right: 2px; 
    width: 25px;
}    

.cont-p {
    text-align: right;
}

.flotador{
    position: absolute;
    height: 100%;
    width: 100%;
    align-Content: center;
    justify-Content: center;
    z-index:10;
    /* background-color: #789f28; */
   
   
}
.flotador1{
    align-Self: flex-start;
    width: 100%;
}

#ocultar {
    display: none;
}

.cerrar{
    color: #fff;
    background-color: #028d7b;
    border: solid 2px aquamarine;
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    display:inline-block ;
    cursor: pointer;


}

.cont-botonce{
    margin-top: 36%;
    text-align: center;
}

.botonce:hover {
    color:  #fff;
    background-color: rgb(35, 80, 69)
}

.boton:hover {
    color:  #fff;
    background-color: rgb(35, 80, 69)
}

.limpiar {
    float: right;
    color: #789f28;
    /* margin-top: 0;*/
    height: 0; 
}
.limpiar:hover {
    cursor: pointer;
}
.sub-titulo input{
    width: 15px !important;
    display:inline-block;
    margin-right: 5px;
}

.sub-titulo{
    width:100%;
}
.marco-principal{
    background-color: #028d7b;
    color: rgb(7, 5, 5);
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
}
.cont-titulo{
    margin-top: 15px;
    color:#fff;
    font-size: 26px;
    text-align: center;
    margin-bottom: 20px;
}

.cont-casos1{
    justify-content: center;
    text-align: center;
    color: #fff;
}

.cont-tablacasosre {
    color: rgb(7, 5, 5);
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
  
  .tablascasosre {
    height: 300px;
    width: 100%;
    width: auto;
    overflow: auto;
  }
  
  .tablare {
    color: #fff;
    background-color: #7ac5cd;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 18px;
    text-align: center;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
  }

  .cont-principalgrab {
    color: rgb(7, 5, 5);
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
  
  .cont-grabador {
    height: 300px;
    width: 100%;
    width: auto;
  }
  
  .grabador {
    color: #fff;
    background-color: #7ac5cd;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 18px;
    text-align: center;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
  }
  .cerrarca{
    background-color: #fc1515;
    background-image: url('../../imagenes/borrar.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block ;
    cursor: pointer;
    margin-top: 2px;
  }
  .titulo-listcerrarca{
    font-size: 30px;
    color: red;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font: bold;
    margin-left: 50px;
    cursor: pointer;
    float: right;
}
.opcionppreca{
    margin-left: 80px;
}
.cont-correo-principal{
    /* border: 15px;
    position:fixed;
    top: 25%;
    left: 0%;
    right: 0;
    margin: 0 auto;
    width: 50%;
    height: 50%;
    justify-content: center;
    font-size: 14px;
    background-color:  #028d7b;;
-webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
-moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
margin-bottom: 15px;
    z-index:80; */





    position: fixed;
    top: 25%;
    height: 60%;
    width: 100%;
    align-Content: center;
    justify-Content: center;
    z-index:180;
    /* background-color: #789f28; */
    
}
.cont-correo{
    height: 200px;
    width: 100%;
    width: auto;
    background-color: #028d7b; 
}
.cont-datos{
    margin-top: 20px;
}
.cont-span{
    justify-Content: center;
}
.titulocorreo{
    color: #fff;
    font-size: 25px;
    margin-top: 20px;
    margin-left: 135px;
}
.opcionco{
    color: aliceblue;
}
#correopa{
    font-size: 18px;
}
.titulo-listcerrarco{
    font-size: 30px;
    color: red;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font: bold;
    margin-top: 20px;
    margin-left: 135px;
    cursor: pointer;
}



/* ------- */

.cont-evogra1{
    border: 15px;
    position:fixed;
    top: 25%;
    left: 20%;
    right: 0;
    margin: 0 auto;
    width: 67%;
    height: 385px;
    justify-content: center;
    font-size: 14px;
    background-color:  #028d7b;;
-webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
-moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
margin-bottom: 15px;
    z-index:80;
    
}
.evogra1{
    text-align: center;
    background-color: #028d7b;
    height: 100%px;
    width: 100%;
}
.titulo-listevo1{
    font-size: 30px;
    color: #48e120;
    text-align: center;
}
.titulo-listcerrar1{
    font-size: 30px;
    color: red;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font: bold;
    /* margin-left: 35px; */
    cursor: pointer;
    float: right;
}
.listevo1 {
    border: 15px;
    position:fixed;
    top: 35%;
    left: 20%;
    right: 0;
    margin: 0 auto;
    width: 70%;
    height: 320px;
    justify-content: center;
    font-size: 16px;
    width: auto;
    overflow: auto;
  
    background-color:  #028d7b;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
  
    z-index:100;
  
   
}
.evotabla2 {
    color: #fff;
    background-color:  #028d7b;
    width: 100%;
    border: 1px solid  #028d7b;
    text-align: left;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
}
.evocuerpo1{
    cursor: pointer;
}