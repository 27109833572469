.estadistica {
    background-color: #028d7b;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
  margin-bottom: 15px;
}
.cont-tituloestadistica{
  margin-bottom: 20px;
  text-align: center;
}
.cont-opcion2 {
    margin-bottom: 15px;
    text-align: center;
    color: #fff;
  
}
.opcion {
  font-size: 15px;
}
.opcione {
  font-size: 11px;
}
#sexom, #sexof {
    width: 100%;
    outline: none;
    margin: 0;
    font-size: 14px;
    height: 25px;
} 
.accionese {
  margin-left: 30px ;
}
.botonest{
    color: #fff;
    background-color: rgb(35, 80, 69);
    outline: none;
    border: solid 2px aquamarine;
    font-size: 12px;
     padding: 3px 3px; 
    border-radius: 10px;  
    margin-left: 20px;

}
.botonest:hover {
  color:  rgb(35, 80, 69);
  background-color: #fff; 
}
.cont-titu-esta {
  text-align: left;
}
.tituloest1{
  font-size: 16px;
  color: #fff;
  align-content: flex-start;
}
.tituloest2{
  margin-left: 50px;
  font-size: 16px;
  color: #fff;
  align-content: flex-start;
}