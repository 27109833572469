.cont-quimioc {
    background-color: #028d7b; 
    height: 100%;
    width: 100%;
    align-Content: center;
    justify-Content: center;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;

}
.cont-tituloquimioc {
    text-align: center;
}
.tituloquimioc {
    text-align: center;
    font-size: 25px;
    color: #fff;   
}
.titulo-cerrarquimioc {
    font-size: 30px;
    color: red;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font: bold;
    cursor: pointer;
    float: right;
}

#cedula3, #edad3, #nombre3, #fechad3 {
width: 100%;
outline: none;
margin: 0;
font-size: 14px;
height: 25px;
} 
#diagnostico3 {
    width: 100%s; 
    outline: none;
    margin: 0;
    font-size: 14px;
    /* height: 25px; */
    } 
.cont-opcion {
    margin-bottom: 15px ;
}
.cont-opcion2 {
    margin-bottom: 15px;
    text-align: center;
    color: #fff;
    font-size: 14px;
}
.spanradio {
    font-size: 13px !important;
    color: seashell !important;
}
.form-radioM, .form-radioF {
    height: 20px !important;
}
#admin{
    margin-top: 10px;
    font-size: 16px;
}
.botongri{
    color: #028d7b;
    background-color: #fff;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 14px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
}
.botongri:hover {
    color:  #fff;
    background-color: rgb(35, 80, 69)
}