.cont-recipec {
    background-color: #028d7b; 
    height: 100%;
    width: 100%;
    align-Content: center;
    justify-Content: center;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px; 

}
.cont-titulorecipec {
    text-align: center;
}
.titulorecipec {
    text-align: center;
    font-size: 25px;
    color: #fff;   
}
.titulo-cerrarrecipec {
    font-size: 30px;
    color: red;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font: bold;
    cursor: pointer;
    float: right;
}
.medicamento{
    font-size: 14px;
    width: 350px;
}
#cedula, #edad, #nombre, #nhisto, #fecha, #medicamento, #indicaciones, #peso, #talla, #sc, #cc, #mm, #ll, #ciclo, #fechaci, #observacion {
width: 100%;
outline: none;
margin: 0;
font-size: 14px;
height: 25px;
} 
#indicaciones{
    height: 210px;
    font-size: 12px;
}
#preme, #recipet {
    width: 100%;
    outline: none;
    margin: 0;
    font-size: 14px;
    /* height: 25px; */
    } 

.cont-opcion {
    margin-bottom: 15px;

}
.cont-opcionr {
    margin-bottom: 15px;
    margin: 0;
    padding: 0;
}
.cont-opcionc {
    
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
    
}

.cont-tablareci{
    color: rgb(7, 5, 5);
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
}
.tablamedi1{
    background-color: #18ccb4;
    -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
    -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
    box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
    margin-bottom: 15px;
}
.cont-listreci {
    color: rgb(7, 5, 5);
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
  
  .listreci {
    padding: 0%;  
    height: 280px;
    width: 100%;
    width: auto;
    overflow: auto;
  }
  
  .tablareci {
    color: #fff;
    background-color: #7ac5cd;
    width: 100%;
    border: 1px solid #7ac5cd;
    font-size: 12px;
    border-collapse: collapse;
    margin: 0 0 1em 0;
    caption-side: top;
    border-collapse: collapse;  
  }
  .cont-titulorecipe{
     text-align: center;
     margin-bottom: 10px;
  }
  .titulo-recipe{
    color: #fff;
    font: bold;
    font-size: 22px;
}
.botonse {
    color: #028d7b;
    background-color: #fff;
    outline: none;
    border: solid 2px aquamarine;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    margin-right: 20px; 
    width: 230px;
}
.botonse:hover {
    color:  #fff;
    background-color: rgb(35, 80, 69)
}
.limpiarre {
    float: left;
    color: #fff;
    /* margin-top: 0;*/
    height: 0; 
}
.limpiarre:hover {
    cursor: pointer;
}

.flotadorre{
    position: absolute;
    height: 100%;
    width: 97%;
    align-Content: center;
    justify-Content: center;
    z-index:10;
    /* height: auto !important; */
    /* background-color: #789f28; */
   
   
}
.flotador1re{
    align-Self: flex-start;
    width: 100%;
}